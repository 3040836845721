import React from 'react';

import atticVideo from 'assets/images/landingAir/airblivious-video/attic-video.webm';
import bbqVideo from 'assets/images/landingAir/airblivious-video/bbq-video.webm';
import carbonMonoxideVideo from 'assets/images/landingAir/airblivious-video/carbon-monoxide-video.webm';
import gamerRoomVideo from 'assets/images/landingAir/airblivious-video/gamer-room-video.webm';
import dustVideo from 'assets/images/landingAir/airblivious-video/dust-video.webm';
import highwayPollutionVideo from 'assets/images/landingAir/airblivious-video/highway-pollution-video.webm';
import outdoorAqiVideo from 'assets/images/landingAir/airblivious-video/outdoor-aqi-video.webm';
import petDanderVideo from 'assets/images/landingAir/airblivious-video/pet-dander-video.webm';
import pollenVideo from 'assets/images/landingAir/airblivious-video/pollen-video.webm';
import smogVideo from 'assets/images/landingAir/airblivious-video/smog-video.webm';
import wildfireSmokeVideo from 'assets/images/landingAir/airblivious-video/wildfire-smoke-video.webm';
import cookingVideo from 'assets/images/landingAir/airblivious-video/cooking-video.webm';

import atticVideoMp4 from 'assets/images/landingAir/airblivious-video/attic-video.mp4';
import bbqVideoMp4 from 'assets/images/landingAir/airblivious-video/bbq-video.mp4';
import carbonMonoxideVideoMp4 from 'assets/images/landingAir/airblivious-video/carbon-monoxide-video.mp4';
import gamerRoomVideoMp4 from 'assets/images/landingAir/airblivious-video/gamer-room-video.mp4';
import dustVideoMp4 from 'assets/images/landingAir/airblivious-video/dust-video.mp4';
import highwayPollutionVideoMp4 from 'assets/images/landingAir/airblivious-video/highway-pollution-video.mp4';
import outdoorAqiVideoMp4 from 'assets/images/landingAir/airblivious-video/outdoor-aqi-video.mp4';
import petDanderVideoMp4 from 'assets/images/landingAir/airblivious-video/pet-dander-video.mp4';
import pollenVideoMp4 from 'assets/images/landingAir/airblivious-video/pollen-video.mp4';
import smogVideoMp4 from 'assets/images/landingAir/airblivious-video/smog-video.mp4';
import wildfireSmokeVideoMp4 from 'assets/images/landingAir/airblivious-video/wildfire-smoke-video.mp4';
import cookingVideoMp4 from 'assets/images/landingAir/airblivious-video/cooking-video.mp4';

import atticVideoPoster from 'assets/images/landingAir/airblivious-video/attic-video-thumbnail.jpg';
import bbqVideoPoster from 'assets/images/landingAir/airblivious-video/bbq-video-thumbnail.jpg';
import carbonMonoxideVideoPoster from 'assets/images/landingAir/airblivious-video/carbon-monoxide-video-thumbnail.jpg';
import gamerRoomVideoPoster from 'assets/images/landingAir/airblivious-video/gamer-room-video-thumbnail.jpg';
import dustVideoPoster from 'assets/images/landingAir/airblivious-video/dust-video-thumbnail.jpg';
import highwayPollutionVideoPoster from 'assets/images/landingAir/airblivious-video/highway-pollution-video-thumbnail.jpg';
import outdoorAqiVideoPoster from 'assets/images/landingAir/airblivious-video/outdoor-aqi-video-thumbnail.jpg';
import petDanderVideoPoster from 'assets/images/landingAir/airblivious-video/pet-dander-video-thumbnail.jpg';
import pollenVideoPoster from 'assets/images/landingAir/airblivious-video/pollen-video-thumbnail.jpg';
import smogVideoPoster from 'assets/images/landingAir/airblivious-video/smog-video-thumbnail.jpg';
import wildfireSmokeVideoPoster from 'assets/images/landingAir/airblivious-video/wildfire-smoke-video-thumbnail.jpg';
import cookingVideoPoster from 'assets/images/landingAir/airblivious-video/cooking-video-thumbnail.jpg';

export const videoItems = [
  {
    videoSrc: atticVideo,
    videoSrcMp4: atticVideoMp4,
    poster: atticVideoPoster,
    quote:
      'Megan was airblivious to the fact that dust, mold spores, and insulation fibers in the attic were hitching regular rides into her air ducts.',
  },
  {
    videoSrc: bbqVideo,
    videoSrcMp4: bbqVideoMp4,
    poster: bbqVideoPoster,
    quote:
      'Jake was airblivious to the fact that his backyard BBQ was filling the air with more PM2.5 than a diesel truck idling at a stoplight.',
  },
  {
    videoSrc: carbonMonoxideVideo,
    videoSrcMp4: carbonMonoxideVideoMp4,
    poster: carbonMonoxideVideoPoster,
    quote:
      'Ethan was airblivious to his heater\'s side hustle: sneaking dangerous carbon monoxide into the air like an undercover villain.',
  },
  {
    videoSrc: gamerRoomVideo,
    videoSrcMp4: gamerRoomVideoMp4,
    poster: gamerRoomVideoPoster,
    quote:
      'Mike was airblivious to the fact that the 3,000 ppm of CO2 in his gamer room was slowing his reaction time by 15%, preventing him from ever defeating the boss.',
  },

  {
    videoSrc: cookingVideo,
    videoSrcMp4: cookingVideoMp4,
    poster: cookingVideoPoster,
    quote: (
      <>
        Jane was airblivious to the fact that her lasagna night generated 120
        µg/m
        <sup>3</sup>
        {' '}
        of respiratory deposition—the equivalent of her family
        puffing 3 cigarettes over dinner.
      </>
    ),
  },
  {
    videoSrc: dustVideo,
    videoSrcMp4: dustVideoMp4,
    poster: dustVideoPoster,
    quote:
      'Sophie was airblivious to the fact that the glittering dust in her sunlit living room was actually a cocktail of allergens, skin flakes, and microscopic pollutants.',
  },
  {
    videoSrc: highwayPollutionVideo,
    videoSrcMp4: highwayPollutionVideoMp4,
    poster: highwayPollutionVideoPoster,
    quote:
      'Kyle was airblivious to the fact that living near the highway meant his apartment was its own 6-lane thoroughfare for pollutants.',
  },

  {
    videoSrc: outdoorAqiVideo,
    videoSrcMp4: outdoorAqiVideoMp4,
    poster: outdoorAqiVideoPoster,
    quote:
      'Gary was airblivious that opening the window would lead to  letting in a cigarette’s worth of PM2.5 every hour',
  },
  {
    videoSrc: petDanderVideo,
    videoSrcMp4: petDanderVideoMp4,
    poster: petDanderVideoPoster,
    quote:
      'Blinded by love, Jessica was airblivious to the fact that her dog’s dander was the main cause of her chronic sinusitis.',
  },
  {
    videoSrc: pollenVideo,
    videoSrcMp4: pollenVideoMp4,
    poster: pollenVideoPoster,
    quote:
      'Emma was airblivious to all the pollen entering through her open windows, filling her space with the absolute worst of the great outdoors.',
  },
  {
    videoSrc: smogVideo,
    videoSrcMp4: smogVideoMp4,
    poster: smogVideoPoster,
    quote:
      'John was airblivious to the fact that the morning \'haze\' was actually smog, with PM2.5 levels that could make a downtown highway blush.',
  },
  {
    videoSrc: wildfireSmokeVideo,
    videoSrcMp4: wildfireSmokeVideoMp4,
    poster: wildfireSmokeVideoPoster,
    quote:
      'Jenna was airblivious to the fact that her afternoon jog amid wildfire smoke was boosting her heart rate by over 5% (and not in a good, cardio kind of way).',
  },
];
